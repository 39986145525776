<template>
  <div class="pa-5">
    <h1>Domande</h1>
    <v-expansion-panels accordion>
      <v-expansion-panel v-for="question in questions" :key="question.title">
        <v-expansion-panel-header>{{ question.title }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-html="question.description"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Ulteriori domande?</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>Contattataci su <a href="mailto:info@trackbook.app">info@trackbook.app</a></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "Faq",
  data() {
    return {
      questions: [
        {
          title: "Che cos'è Trackbook?",
          description: "Trackbook è un sito web/app per tracciare qualsiasi cosa tu voglia, dai piatti agli all you can eat alla lista della spesa."
        },
        {
          title: "Come scarico Trackbook?",
          description: "Per scaricasre Trackbook su iOS è possibile solo tramite safari, cliccando sull'icona di condivisione e selezionando tra le voci <strong>\"Aggiungi alla schermata Home\"</strong>.<br/>Su Android appena si accede al sito appare sotto un banner con scritto <strong>\"Aggiungi Trackbook alla schermata Home\"</strong>, quindi va cliccato e automaticamente apparirà nella Home."
        },
        {
          title: "Come funziona Trackbook?",
          description: "Trackbook può tracciare tutte le categorie che desideri e in ogni categoria inserire ogni cosa che vuoi tracciare e segnare gli eventi che modificano i dati da tracciare."
        },
        {
          title: "Come creo una nuova categoria?",
          description: "Basta recarsi nella pagina di Dashboard e cliccare sul tasto +, selezionare categoria e inserire il nome."
        },
        {
          title: "Come creo una nuova tab?",
          description: "Basta recarsi nella pagina di Dashboard e nella categoria in cui si vuole inserire la nuova tab, cliccare sul tasto +, selezionare categoria e inserire il nome, sottotitolo se necessario ed eventuali campi aggiuntivi quali: <ul><li><strong>\"Con stato avanzamento?\"</strong>, che permette ad ogni evento di assegnare uno stato di avanzamento tra Inizio, Svolgimento, Fine, Annullamento</li><li><strong>\"Con check?\"</strong>, che permette ad ogni evento di assegnare se l'evento è stato completato</li><li><strong>\"Con totale?\"</strong>, che permette di creare un totale per la tab a cui ogni evento si assegna un valore</li><li><strong>\"Tipo totale?\"</strong>, che permette di sceglie se il totale sarà una somma dei valori o se assegnare un budget a cui si sottraggono i valori</li><li><strong>\"Budget\"</strong>, che permette di assegnare il budget</li></ul>"
        },
        {
          title: "Come creo un nuovo evento?",
          description: "Basta recarsi nella pagina di dettaglio della tab a cui si vuole aggiungere l'evento, cliccare sul tasto +, selezionare categoria e inserire il nome, sottotitolo ed eventuali campi aggiuntivi se assegnati precedentemente dalla scelta della tab."
        }
      ]
    }
  }
}
</script>

<style>

</style>